import React from 'react';

import { Container, Box, Typography, Paper } from '@mui/material';

const NotAuthorizedPage = () => {
  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper elevation={1}>
          <Box sx={{ padding: '1rem' }}>
            <Typography variant="h3">401: Not Authorized</Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default NotAuthorizedPage;
